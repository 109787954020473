import React, { Component } from 'react';

import { RedocStandalone } from 'redoc';

class CommonsV1Page extends Component {
  render() {
    return (
      <RedocStandalone specUrl="/commons/commons.backend.v1.yaml"/>
    )
  }
}

export default CommonsV1Page;