import React from 'react';

import { BrowserRouter as Router, Route} from 'react-router-dom';

import Navigation from './components/Navigation.js';
import PumpkinV2Page from './components/PumpkinV2Page.js';
import PumpkinV4Page from './components/PumpkinV4Page.js';
import CommonsV1Page from './components/CommonsV1Page.js';

import './App.css';

function App() {

  return (
    <div className="App">
      <Router>
        <div>
          <Navigation />
          <Route name="pumpkin" exact path="/" component={PumpkinV4Page} />
          <Route name="pumpkin" exact path="/pumpkin/v2" component={PumpkinV2Page} />
          <Route name="pumpkin" exact path="/pumpkin/v4" component={PumpkinV4Page} />
          <Route name="commons" exact path="/commons/v1" component={CommonsV1Page} />
        </div>
      </Router>    
    </div>
  );

}

export default App;
