import React, { Component } from 'react';

import { RedocStandalone } from 'redoc';

class PumpkinV2Page extends Component {
  render() {
    return (
      <RedocStandalone specUrl="/pumpkin/pumpkin.frontend.v2.yaml"/>
    )
  }
}

export default PumpkinV2Page;




