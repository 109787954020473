import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import Drawer from '@material-ui/core/Drawer';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Toolbar from '@material-ui/core/Toolbar';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import StorageIcon from '@material-ui/icons/Storage';

class Navigation extends Component {


  constructor(props) {
    super(props);
    this.state = {
      drawerIsOpen: false
    }
    
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  toggleDrawer(drawerIsOpen) {
    console.log(drawerIsOpen);
    this.setState({drawerIsOpen: drawerIsOpen});
  };

  render() {
    return (
      <React.Fragment>        
        <CssBaseline />
        <AppBar position="static" className="App-bar">

        <Drawer anchor="left" open={this.state.drawerIsOpen} onClose={() => this.toggleDrawer(false)}>
          <div
            role="presentation"
            onClick={() => this.toggleDrawer(false)}
            >
            <List className="List">
                <ListItem button key="frontend-v4">
                  <ListItemIcon><PhoneIphoneIcon /></ListItemIcon>
                  <ListItemText><Link to="/pumpkin/v4">Pumpkin frontend v4</Link></ListItemText>
                </ListItem>
                <ListItem button key="frontend-v2">
                  <ListItemIcon><PhoneIphoneIcon /></ListItemIcon>
                  <ListItemText><Link to="/pumpkin/v2">Pumpkin frontend v2</Link></ListItemText>
                </ListItem>
            </List>
            <Divider />
            <List className="List">
                <ListItem button key="commons-v1">
                  <ListItemIcon><StorageIcon /></ListItemIcon>
                  <ListItemText><Link to="/commons/v1">Commons backend v1</Link></ListItemText>
                </ListItem>
            </List>
          </div>
        </Drawer>

          <Toolbar>
            <IconButton edge="start" className="Menu-button" 
              color="inherit" aria-controls="simple-menu" aria-haspopup="true" 
              aria-label="menu" onClick={() => this.toggleDrawer(true)} >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    )
  }

/*
  render() {
    return (
      <React.Fragment>        
        <CssBaseline />
        <AppBar position="static" className="App-bar">
            <Toolbar>
                <Button color="inherit"><Link to="/">Frontend</Link></Button>
                <Button color="inherit"><Link to="/commons">Commons Backend</Link></Button>
            </Toolbar>
        </AppBar>
      </React.Fragment>
    )
  }
*/
}

export default Navigation;




